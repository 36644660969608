import React from 'react';
import ProductCard from '../components/ProductCard';

const products = [
  {
    id: "original-beef",
    name: "Original Beef Jerky",
    price: 12.99,
    image: "https://images.unsplash.com/photo-1504674900247-0877df9cc836?auto=format&fit=crop&q=80&w=2070",
    description: "Classic smoky flavor with a perfect blend of spices"
  },
  {
    id: "spicy-habanero",
    name: "Spicy Habanero",
    price: 13.99,
    image: "https://images.unsplash.com/photo-1603360946369-dc9bb6258143?auto=format&fit=crop&q=80&w=2070",
    description: "Fire-kissed jerky with intense habanero heat"
  },
  {
    id: "teriyaki-blend",
    name: "Teriyaki Blend",
    price: 13.99,
    image: "https://images.unsplash.com/photo-1544025162-d76694265947?auto=format&fit=crop&q=80&w=2069",
    description: "Sweet and savory Asian-inspired marinade"
  }
];

export default function Shop() {
  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Our Products</h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Discover our selection of premium beef jerky, handcrafted with care using traditional methods
            and the finest ingredients.
          </p>
        </div>

        {/* Products Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {products.map((product) => (
            <ProductCard key={product.id} {...product} />
          ))}
        </div>
      </div>
    </div>
  );
}