import React, { useState, useEffect } from 'react';
import { X, Mail, Lock, User, LogIn } from 'lucide-react';
import { useAuthStore } from '../store/authStore';
import { useNavigate } from 'react-router-dom';
import { auth } from '../lib/firebase';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function AuthModal({ isOpen, onClose }: AuthModalProps) {
  const navigate = useNavigate();
  const [view, setView] = useState<'signin' | 'signup' | 'forgot-password' | 'confirmation'>('signin');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [confirmationEmail, setConfirmationEmail] = useState('');
  const [resetSent, setResetSent] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  const { signIn, signInWithGoogle, signUp, resetPassword, loading, error: authError, user, clearError } = useAuthStore();

  // Reset form when modal is closed
  useEffect(() => {
    if (!isOpen) {
      setEmail('');
      setPassword('');
      setFirstName('');
      setLastName('');
      setConfirmationEmail('');
      setResetSent(false);
      setValidationErrors({});
      setView('signin');
      clearError();
    }
  }, [isOpen, clearError]);

  // Close modal when user is authenticated
  useEffect(() => {
    if (user) {
      onClose();
    }
  }, [user, onClose]);

  const validateForm = () => {
    const errors: Record<string, string> = {};
    
    if (!email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Please enter a valid email address';
    }

    if (view === 'signup' || view === 'signin') {
      if (!password) {
        errors.password = 'Password is required';
      } else if (password.length < 6) {
        errors.password = 'Password must be at least 6 characters';
      }
    }

    if (view === 'signup') {
      if (!firstName) errors.firstName = 'First name is required';
      if (!lastName) errors.lastName = 'Last name is required';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm() || loading) {
      return;
    }

    try {
      if (view === 'signin') {
        await signIn(email, password);
        // Modal will auto-close due to user effect
      } else if (view === 'signup') {
        await signUp(email, password, firstName, lastName);
        setConfirmationEmail(email);
        setView('confirmation');
      } else if (view === 'forgot-password') {
        await resetPassword(email);
        setResetSent(true);
      }
    } catch (err) {
      console.error('Auth error:', err);
      setValidationErrors({
        form: err instanceof Error ? err.message : 'Authentication failed'
      });
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      onClose();
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      // Note: We don't need to manually navigate here as the OAuth flow will handle the redirect
    } catch (err) {
      console.error('Google sign in error:', err);
      setValidationErrors({
        form: err instanceof Error ? err.message : 'Failed to sign in with Google'
      });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4">
        <div className="fixed inset-0 bg-black bg-opacity-25" onClick={onClose} />
        
        <div className="relative w-full max-w-md transform rounded-lg bg-white p-6 text-left shadow-xl transition-all">
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-400 hover:text-gray-500"
          >
            <X className="h-5 w-5" />
          </button>

          {view === 'confirmation' ? (
            <div className="text-center">
              <Mail className="mx-auto h-12 w-12 text-amber-600" />
              <h2 className="mt-4 text-lg font-medium text-gray-900">Check your email</h2>
              <p className="mt-2 text-sm text-gray-500">
                We've sent a confirmation link to<br />
                <span className="font-medium text-gray-900">{confirmationEmail}</span>
              </p>
              <button
                onClick={onClose}
                className="mt-6 w-full rounded-md bg-amber-600 px-4 py-2 text-sm font-medium text-white hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2"
              >
                Got it
              </button>
            </div>
          ) : view === 'forgot-password' && resetSent ? (
            <div className="text-center">
              <Mail className="mx-auto h-12 w-12 text-amber-600" />
              <h2 className="mt-4 text-lg font-medium text-gray-900">Check your email</h2>
              <p className="mt-2 text-sm text-gray-500">
                We've sent password reset instructions to<br />
                <span className="font-medium text-gray-900">{email}</span>
              </p>
              <button
                onClick={() => {
                  setView('signin');
                  setResetSent(false);
                }}
                className="mt-6 w-full rounded-md bg-amber-600 px-4 py-2 text-sm font-medium text-white hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2"
              >
                Return to Sign In
              </button>
            </div>
          ) : (
            <>
              <h2 className="text-xl font-semibold text-gray-900">
                {view === 'signin' ? 'Sign In' : view === 'signup' ? 'Create Account' : 'Reset Password'}
              </h2>
              
              {(view === 'signin' || view === 'signup') && (
                <button
                  onClick={handleGoogleSignIn}
                  disabled={loading}
                  className="mt-4 w-full flex items-center justify-center gap-2 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                >
                  <img src="https://www.google.com/favicon.ico" alt="Google" className="w-4 h-4" />
                  Continue with Google
                </button>
              )}

              <div className="relative my-6">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Or continue with email</span>
                </div>
              </div>

              <form onSubmit={handleSubmit} className="space-y-4">
                {(validationErrors.form || authError) && (
                  <div className="p-3 bg-red-100 text-red-700 rounded-md text-sm">
                    {validationErrors.form || authError}
                  </div>
                )}

                {view === 'signup' && (
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                        First Name
                      </label>
                      <div className="mt-1 relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                          <User className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="text"
                          id="firstName"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          className={`block w-full pl-10 pr-3 py-2 border rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm ${
                            validationErrors.firstName ? 'border-red-300' : 'border-gray-300'
                          }`}
                        />
                      </div>
                      {validationErrors.firstName && (
                        <p className="mt-1 text-xs text-red-600">{validationErrors.firstName}</p>
                      )}
                    </div>
                    <div>
                      <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                        Last Name
                      </label>
                      <div className="mt-1 relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                          <User className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="text"
                          id="lastName"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          className={`block w-full pl-10 pr-3 py-2 border rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm ${
                            validationErrors.lastName ? 'border-red-300' : 'border-gray-300'
                          }`}
                        />
                      </div>
                      {validationErrors.lastName && (
                        <p className="mt-1 text-xs text-red-600">{validationErrors.lastName}</p>
                      )}
                    </div>
                  </div>
                )}

                <div>
                  <label htmlFor="auth-email" className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <div className="mt-1 relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                      <Mail className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="email"
                      id="auth-email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className={`block w-full pl-10 pr-3 py-2 border rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm ${
                        validationErrors.email ? 'border-red-300' : 'border-gray-300'
                      }`}
                    />
                  </div>
                  {validationErrors.email && (
                    <p className="mt-1 text-xs text-red-600">{validationErrors.email}</p>
                  )}
                </div>

                {view !== 'forgot-password' && (
                  <div>
                    <label htmlFor="auth-password" className="block text-sm font-medium text-gray-700">
                      Password
                    </label>
                    <div className="mt-1 relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                        <Lock className="h-5 w-5 text-gray-400" />
                      </div>
                      <input
                        type="password"
                        id="auth-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className={`block w-full pl-10 pr-3 py-2 border rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm ${
                          validationErrors.password ? 'border-red-300' : 'border-gray-300'
                        }`}
                      />
                    </div>
                    {validationErrors.password && (
                      <p className="mt-1 text-xs text-red-600">{validationErrors.password}</p>
                    )}
                  </div>
                )}

                {view === 'signin' && (
                  <div className="text-right">
                    <button
                      type="button"
                      onClick={() => setView('forgot-password')}
                      className="text-sm text-amber-600 hover:text-amber-500"
                    >
                      Forgot password?
                    </button>
                  </div>
                )}

                <button
                  type="submit"
                  disabled={loading}
                  className="w-full rounded-md bg-amber-600 px-4 py-2 text-sm font-medium text-white hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 disabled:opacity-50"
                >
                  {loading 
                    ? 'Please wait...' 
                    : view === 'signin' 
                      ? 'Sign In' 
                      : view === 'signup' 
                        ? 'Sign Up' 
                        : 'Reset Password'}
                </button>
              </form>

              {view === 'signin' ? (
                <p className="mt-4 text-center text-sm text-gray-500">
                  Don't have an account?{' '}
                  <button
                    onClick={() => setView('signup')}
                    className="text-amber-600 hover:text-amber-500"
                  >
                    Sign up
                  </button>
                </p>
              ) : view === 'signup' ? (
                <p className="mt-4 text-center text-sm text-gray-500">
                  Already have an account?{' '}
                  <button
                    onClick={() => setView('signin')}
                    className="text-amber-600 hover:text-amber-500"
                  >
                    Sign in
                  </button>
                </p>
              ) : (
                <p className="mt-4 text-center text-sm text-gray-500">
                  Remember your password?{' '}
                  <button
                    onClick={() => setView('signin')}
                    className="text-amber-600 hover:text-amber-500"
                  >
                    Sign in
                  </button>
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}