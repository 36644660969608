import React from 'react';
import { Link } from 'react-router-dom';

export default function About() {
  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="relative h-96 mb-12">
          <img
            src="https://images.unsplash.com/photo-1518992028580-6d57bd80f2d2?auto=format&fit=crop&q=80&w=2069"
            alt="Uncle Q's Kitchen"
            className="w-full h-full object-cover rounded-lg"
          />
          <div className="absolute inset-0 bg-black bg-opacity-40 rounded-lg"></div>
          <div className="absolute inset-0 flex items-center justify-center">
            <h1 className="text-4xl md:text-5xl font-bold text-white text-center">
              Our Story
            </h1>
          </div>
        </div>

        {/* History Section */}
        <div className="prose prose-lg mx-auto">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">A Family Tradition</h2>
          <p className="text-gray-600 mb-8">
            Uncle Q's Jerky began in 1993 in a small family kitchen in rural Montana. What started as
            Uncle Quincy's weekend hobby quickly became a passion for creating the perfect beef jerky.
            Using only the finest cuts of beef and a secret blend of spices passed down through
            generations, Uncle Q perfected his recipe over countless batches.
          </p>

          <div className="grid md:grid-cols-2 gap-8 my-12">
            <img
              src="https://images.unsplash.com/photo-1542528180-a1208c5169a5?auto=format&fit=crop&q=80&w=1955"
              alt="Traditional Smoking Process"
              className="rounded-lg shadow-lg"
            />
            <div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">Traditional Methods</h3>
              <p className="text-gray-600">
                We still use the same traditional smoking process that Uncle Q developed. Each batch
                is carefully marinated for 24 hours in our signature blend of spices, then slowly
                smoked over hickory wood to achieve the perfect texture and flavor profile that our
                customers have come to love.
              </p>
            </div>
          </div>

          <h2 className="text-3xl font-bold text-gray-900 mb-6">From Family to Community</h2>
          <p className="text-gray-600 mb-8">
            Word of Uncle Q's delicious jerky spread quickly through the local community. What began
            as sharing with friends and family at gatherings soon turned into a small business
            operating out of a converted garage. Today, while we've grown beyond that garage, we
            still maintain the same commitment to quality and traditional methods that Uncle Q
            insisted upon.
          </p>

          <div className="grid md:grid-cols-2 gap-8 my-12">
            <div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">Our Values</h3>
              <ul className="list-disc list-inside text-gray-600 space-y-2">
                <li>100% premium cuts of beef</li>
                <li>No artificial preservatives</li>
                <li>Small-batch production</li>
                <li>Traditional smoking process</li>
                <li>Sustainable packaging</li>
                <li>Supporting local farmers</li>
              </ul>
            </div>
            <img
              src="https://images.unsplash.com/photo-1551446591-142875a901a1?auto=format&fit=crop&q=80&w=1925"
              alt="Quality Ingredients"
              className="rounded-lg shadow-lg"
            />
          </div>

          <h2 className="text-3xl font-bold text-gray-900 mb-6">Looking Forward</h2>
          <p className="text-gray-600 mb-8">
            While Uncle Q has since passed the torch to the next generation, his legacy lives on in
            every batch we produce. We remain committed to his vision of creating the highest
            quality beef jerky while maintaining the personal touch that makes our product special.
          </p>

          <div className="bg-amber-50 p-8 rounded-lg my-12">
            <h3 className="text-2xl font-bold text-amber-900 mb-4">Visit Our Shop</h3>
            <p className="text-amber-800 mb-6">
              Experience the tradition of Uncle Q's Jerky for yourself. Try our signature flavors
              and taste the difference that quality ingredients and time-honored methods make.
            </p>
            <Link
              to="/"
              className="inline-block bg-amber-600 text-white px-6 py-3 rounded-md font-semibold hover:bg-amber-700 transition-colors duration-300"
            >
              Shop Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}