import { Menu, ShoppingCart, User, LogOut, UserCircle } from 'lucide-react';
import { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuthStore } from '../store/authStore';
import Cart from './Cart';
import { useCartStore } from '../store/cartStore';

export default function Navbar({ onAuthClick }: { onAuthClick: () => void }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [prevCount, setPrevCount] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { user, signOut } = useAuthStore();
  const items = useCartStore((state) => state.items);
  const showToast = useCartStore((state) => state.showToast);
  const itemCount = items.reduce((sum, item) => sum + item.quantity, 0);

  // Handle click outside user menu
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const userMenu = document.getElementById('user-menu');
      if (userMenu && !userMenu.contains(event.target as Node)) {
        setIsUserMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Trigger animation when showToast changes or itemCount changes
  useEffect(() => {
    if (showToast || itemCount !== prevCount) {
      setIsAnimating(true);
      setPrevCount(itemCount);
      const timer = setTimeout(() => {
        setIsAnimating(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [showToast, itemCount, prevCount]);

  const handleSignOut = async () => {
    await signOut();
    setIsUserMenuOpen(false);
    navigate('/');
  };

  const handleAccountClick = () => {
    setIsUserMenuOpen(false);
    navigate('/account');
  };

  return (
    <>
      <nav className="fixed w-full bg-white/95 backdrop-blur-sm z-50 shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center">
              <Link to="/" className="text-2xl font-bold text-amber-800">
                Uncle Q's Jerky
              </Link>
            </div>
            
            <div className="hidden md:flex items-center space-x-8">
              <Link to="/" className="text-gray-700 hover:text-amber-800">
                Home
              </Link>
              <Link to="/shop" className="text-gray-700 hover:text-amber-800">
                Shop
              </Link>
              <Link to="/about" className="text-gray-700 hover:text-amber-800">
                About
              </Link>
              <Link to="/contact" className="text-gray-700 hover:text-amber-800">
                Contact
              </Link>
            </div>

            <div className="flex items-center space-x-4">
              <div className="relative" id="user-menu">
                <button 
                  className="p-2 hover:bg-gray-100 rounded-full"
                  onClick={() => user ? setIsUserMenuOpen(!isUserMenuOpen) : onAuthClick()}
                >
                  <User className="h-5 w-5 text-gray-700" />
                </button>

                {/* User menu dropdown */}
                {user && isUserMenuOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5">
                    <div className="px-4 py-2 text-sm text-gray-700 border-b">
                      Hello, {user.displayName?.split(' ')[0] || 'there'}
                    </div>
                    <button
                      onClick={handleAccountClick}
                      className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                    >
                      <UserCircle className="h-4 w-4 mr-2" />
                      Account
                    </button>
                    <button
                      onClick={handleSignOut}
                      className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center border-t"
                    >
                      <LogOut className="h-4 w-4 mr-2" />
                      Sign Out
                    </button>
                  </div>
                )}
              </div>
              <button 
                className={`p-2 hover:bg-gray-100 rounded-full relative ${
                  isAnimating ? 'animate-shake' : ''
                }`}
                onClick={() => setIsCartOpen(true)}
              >
                <ShoppingCart className="h-5 w-5 text-gray-700" />
                {itemCount > 0 && (
                  <span 
                    key={itemCount}
                    className={`absolute top-0 right-0 bg-amber-600 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center animate-fade-scale`}
                  >
                    {itemCount}
                  </span>
                )}
              </button>
              <button 
                className="md:hidden p-2 hover:bg-gray-100 rounded-full"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <Menu className="h-5 w-5 text-gray-700" />
              </button>
            </div>
          </div>
        </div>
        
        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <Link
                to="/"
                className="block px-3 py-2 text-gray-700 hover:bg-gray-100 rounded-md"
                onClick={() => setIsMenuOpen(false)}
              >
                Home
              </Link>
              <Link
                to="/shop"
                className="block px-3 py-2 text-gray-700 hover:bg-gray-100 rounded-md"
                onClick={() => setIsMenuOpen(false)}
              >
                Shop
              </Link>
              <Link
                to="/about"
                className="block px-3 py-2 text-gray-700 hover:bg-gray-100 rounded-md"
                onClick={() => setIsMenuOpen(false)}
              >
                About
              </Link>
              <Link
                to="/contact"
                className="block w-full text-left px-3 py-2 text-gray-700 hover:bg-gray-100 rounded-md"
                onClick={() => setIsMenuOpen(false)}
              >
                Contact
              </Link>
            </div>
          </div>
        )}
      </nav>

      <Cart isOpen={isCartOpen} onClose={() => setIsCartOpen(false)} />
    </>
  );
}