import { ShoppingCart } from 'lucide-react';
import { useCartStore } from '../store/cartStore';

export default function Toast() {
  const showToast = useCartStore((state) => state.showToast);

  if (!showToast) return null;

  return (
    <div className="fixed bottom-4 right-4 z-50 animate-slide-up">
      <div className="bg-green-600 text-white px-4 py-2 rounded-lg shadow-lg flex items-center space-x-2">
        <ShoppingCart className="h-5 w-5" />
        <span>Added to cart!</span>
      </div>
    </div>
  );
}