import { auth } from './lib/firebase';
import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { useAuthStore } from './store/authStore';
import Navbar from './components/Navbar';
import AuthModal from './components/AuthModal';
import Toast from './components/Toast';
import Account from './pages/Account';
import Success from './pages/Success';
import About from './pages/About';
import Shop from './pages/Shop';
import Contact from './pages/Contact';
import { Flame, Award, Leaf } from 'lucide-react';
import LeadCaptureModal from './components/LeadCaptureModal';

// ScrollToSection component to handle scroll behavior when navigating
function ScrollToSection() {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollTo) {
      const element = document.getElementById(location.state.scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return null;
}

function App() {
  const navigate = useNavigate();
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const setUser = useAuthStore(state => state.setUser);
  const [isLeadModalOpen, setIsLeadModalOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, [setUser]);

  useEffect(() => {
    // Check if user has already seen the modal
    const hasSeenModal = localStorage.getItem('leadCaptured');
    
    if (!hasSeenModal) {
      // Wait 3 seconds before showing the modal
      const timer = setTimeout(() => {
        setIsLeadModalOpen(true);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, []);

  const products = [
    {
      id: "original-beef",
      name: "Original Beef Jerky",
      price: 12.99,
      image: "https://images.unsplash.com/photo-1504674900247-0877df9cc836?auto=format&fit=crop&q=80&w=2070",
      description: "Classic smoky flavor with a perfect blend of spices"
    },
    {
      id: "spicy-habanero",
      name: "Spicy Habanero",
      price: 13.99,
      image: "https://images.unsplash.com/photo-1603360946369-dc9bb6258143?auto=format&fit=crop&q=80&w=2070",
      description: "Fire-kissed jerky with intense habanero heat"
    },
    {
      id: "teriyaki-blend",
      name: "Teriyaki Blend",
      price: 13.99,
      image: "https://images.unsplash.com/photo-1544025162-d76694265947?auto=format&fit=crop&q=80&w=2069",
      description: "Sweet and savory Asian-inspired marinade"
    }
  ];

  return (
    <>
      <ScrollToSection />
      <div className="min-h-screen bg-gray-50">
        <LeadCaptureModal 
          isOpen={isLeadModalOpen}
          onClose={() => setIsLeadModalOpen(false)}
        />
        <Navbar onAuthClick={() => setIsAuthModalOpen(true)} />
        <AuthModal isOpen={isAuthModalOpen} onClose={() => setIsAuthModalOpen(false)} />
        <Toast />
        
        <Routes>
          <Route path="/account" element={<Account />} />
          <Route path="/success" element={<Success />} />
          <Route path="/about" element={<About />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/" element={
            <>
              {/* Hero Section */}
              <div className="relative h-screen">
                <div className="absolute inset-0">
                  <img
                    src="https://images.unsplash.com/photo-1504674900247-0877df9cc836?auto=format&fit=crop&q=80&w=2070"
                    alt="Beef Jerky Hero"
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-black opacity-50"></div>
                </div>
                <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full flex items-center">
                  <div className="text-white">
                    <h1 className="text-5xl md:text-6xl font-bold leading-tight">
                      Handcrafted Beef Jerky<br />Made with Love
                    </h1>
                    <p className="mt-4 text-xl md:text-2xl max-w-2xl">
                      Small-batch jerky made from premium cuts, marinated in our secret family recipe
                    </p>
                    <button 
                      onClick={() => navigate('/shop')}
                      className="mt-8 bg-amber-600 text-white px-8 py-3 rounded-md text-lg font-semibold hover:bg-amber-700 transition-colors duration-300 inline-block"
                    >
                      Shop Now
                    </button>
                  </div>
                </div>
              </div>

              {/* Features */}
              <div className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div className="text-center">
                      <div className="mx-auto h-12 w-12 text-amber-600">
                        <Leaf className="w-full h-full" />
                      </div>
                      <h3 className="mt-4 text-xl font-semibold">All Natural</h3>
                      <p className="mt-2 text-gray-600">No preservatives or artificial ingredients</p>
                    </div>
                    <div className="text-center">
                      <div className="mx-auto h-12 w-12 text-amber-600">
                        <Flame className="w-full h-full" />
                      </div>
                      <h3 className="mt-4 text-xl font-semibold">Slow Smoked</h3>
                      <p className="mt-2 text-gray-600">Traditional smoking process for perfect flavor</p>
                    </div>
                    <div className="text-center">
                      <div className="mx-auto h-12 w-12 text-amber-600">
                        <Award className="w-full h-full" />
                      </div>
                      <h3 className="mt-4 text-xl font-semibold">Premium Quality</h3>
                      <p className="mt-2 text-gray-600">Made from select cuts of beef</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* About Section */}
              <div id="about" className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
                    <div>
                      <h2 className="text-3xl font-bold mb-6">Our Story</h2>
                      <p className="text-gray-600 text-lg leading-relaxed">
                        Uncle Q's Jerky started in our family kitchen over 30 years ago. What began as a hobby
                        turned into a passion for creating the perfect beef jerky. Using only the finest cuts
                        of beef and our secret blend of spices, we've perfected our recipe over generations.
                      </p>
                      <button 
                        onClick={() => navigate('/about')}
                        className="mt-8 bg-amber-600 text-white px-6 py-3 rounded-md font-semibold hover:bg-amber-700 transition-colors duration-300"
                      >
                        Learn More
                      </button>
                    </div>
                    <div className="relative h-96">
                      <img
                        src="https://images.unsplash.com/photo-1518992028580-6d57bd80f2d2?auto=format&fit=crop&q=80&w=2069"
                        alt="Uncle Q's Kitchen"
                        className="w-full h-full object-cover rounded-lg shadow-xl"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Footer Section */}
              <div id="footer" className="bg-gray-900 text-white py-12">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                    {/* Company Info */}
                    <div>
                      <h3 className="text-xl font-bold mb-2">Uncle Q's Jerky</h3>
                      <p className="text-gray-400">Handcrafted with love since 1993</p>
                    </div>

                    {/* Quick Links */}
                    <div>
                      <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
                      <nav className="space-y-3">
                        <button 
                          onClick={() => navigate('/')}
                          className="block text-gray-400 hover:text-white transition-colors"
                        >
                          Home
                        </button>
                        <button 
                          onClick={() => navigate('/shop')}
                          className="block text-gray-400 hover:text-white transition-colors"
                        >
                          Shop
                        </button>
                        <button 
                          onClick={() => navigate('/about')}
                          className="block text-gray-400 hover:text-white transition-colors"
                        >
                          About
                        </button>
                        <button 
                          onClick={() => navigate('/contact')}
                          className="block text-gray-400 hover:text-white transition-colors"
                        >
                          Contact
                        </button>
                      </nav>
                    </div>

                    {/* Visit Us */}
                    <div>
                      <h4 className="text-lg font-semibold mb-4">Visit Us</h4>
                      <address className="text-gray-400 not-italic space-y-1">
                        <p>123 Jerky Lane</p>
                        <p>Beef Town, ST 12345</p>
                        <p>contact@uncleqs.com</p>
                      </address>
                    </div>
                  </div>

                  {/* Copyright */}
                  <div className="mt-12 pt-8 border-t border-gray-800 text-center text-gray-400">
                    <p>&copy; {new Date().getFullYear()} Uncle Q's Jerky. All rights reserved.</p>
                  </div>
                </div>
              </div>
            </>
          } />
        </Routes>
      </div>
    </>
  );
}

export default App;