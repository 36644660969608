import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/authStore';
import OrderHistory from '../components/OrderHistory';
import { doc, getDoc, setDoc, collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Pencil, X, Check, Loader2 } from 'lucide-react';
import { Loader } from '@googlemaps/js-api-loader';
import { Timestamp } from 'firebase/firestore';

interface UserProfile {
  address: string;
  displayName: string;
}

// Add this type for the Google Places Autocomplete
declare global {
  interface Window {
    google: any;
  }
}

interface Order {
  id: string;
  user_id: string;
  created_at: Timestamp;
  status: 'pending' | 'processing' | 'completed' | 'cancelled';
  total: number;
  items: {
    product_id: string;
    quantity: number;
    price: number;
    name: string;
  }[];
}

export default function Account() {
  const navigate = useNavigate();
  const { user, signOut } = useAuthStore();
  const [isEditing, setIsEditing] = useState(false);
  const [profile, setProfile] = useState<UserProfile | null>({
    displayName: user?.displayName || '',
    address: ''
  });
  const [editedFields, setEditedFields] = useState({
    displayName: user?.displayName || '',
    address: ''
  });
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);
  const autocompleteInput = useRef<HTMLInputElement>(null);
  const autocomplete = useRef<google.maps.places.Autocomplete | null>(null);
  const [orders, setOrders] = useState<Order[]>([]);

  useEffect(() => {
    if (!user) {
      navigate('/');
      return;
    }

    async function fetchProfile() {
      if (!user) return;
      try {
        const profileRef = doc(db, 'profiles', user.uid);
        const profileSnap = await getDoc(profileRef);

        if (profileSnap.exists()) {
          const profileData = profileSnap.data() as UserProfile;
          setProfile(profileData);
          setEditedFields({
            displayName: profileData.displayName || user.displayName || '',
            address: profileData.address || ''
          });
        } else {
          // Create a new profile document if it doesn't exist
          const newProfile = {
            displayName: user.displayName || '',
            address: ''
          };
          await setDoc(profileRef, newProfile);
          setProfile(newProfile);
          setEditedFields(newProfile);
        }
      } catch (err) {
        console.error('Error fetching profile:', err);
        setError('Failed to load profile');
      }
    }

    fetchProfile();
  }, [user, navigate]);

  // Load Google Maps API
  useEffect(() => {
    const loader = new Loader({
      apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
      version: "weekly",
      libraries: ["places"]
    });

    loader.load().then(() => {
      setIsGoogleLoaded(true);
    }).catch((error) => {
      console.error('Error loading Google Maps API:', error);
    });
  }, []);

  // Initialize Autocomplete
  useEffect(() => {
    if (isGoogleLoaded && autocompleteInput.current && isEditing) {
      autocomplete.current = new window.google.maps.places.Autocomplete(
        autocompleteInput.current,
        {
          componentRestrictions: { country: ["us"] }, // Restrict to US addresses
          fields: ["address_components", "formatted_address"],
          types: ["address"]
        }
      );

      // Handle place selection
      if (autocomplete.current) {
        autocomplete.current.addListener("place_changed", () => {
          const place = autocomplete.current?.getPlace();
          if (place?.formatted_address) {
            setEditedFields(prev => ({
              ...prev,
              address: place.formatted_address || ''
            }));
          }
        });
      }

      // Cleanup
      return () => {
        if (autocomplete.current) {
          google.maps.event.clearInstanceListeners(autocomplete.current);
        }
      };
    }
  }, [isGoogleLoaded, isEditing]);

  useEffect(() => {
    async function fetchOrders() {
      if (!user) {
        console.log('No user authenticated');
        return;
      }

      try {
        console.log('Current user ID:', user.uid);
        
        const ordersRef = collection(db, 'orders');
        const q = query(
          ordersRef,
          where('user_id', '==', user.uid),
          orderBy('created_at', 'desc')
        );

        console.log('Executing query...');
        const querySnapshot = await getDocs(q);
        console.log('Query results:', querySnapshot.size, 'documents found');
        
        // Log the first document if it exists
        if (!querySnapshot.empty) {
          console.log('Sample document data:', querySnapshot.docs[0].data());
        }

        const orders = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Order[];
        
        setOrders(orders);
      } catch (error) {
        console.error('Detailed fetch error:', error);
        if (error instanceof Error) {
          console.error('Error message:', error.message);
          console.error('Error stack:', error.stack);
        }
        setError('Failed to fetch orders');
      }
    }

    fetchOrders();
  }, [user]);

  const handleSave = async () => {
    if (!user) return;

    console.log('Save started');
    setIsSaving(true);
    setError(null);

    try {
      // Update profiles collection
      const profileRef = doc(db, 'profiles', user.uid);
      await setDoc(profileRef, {
        displayName: editedFields.displayName,
        address: editedFields.address,
        updatedAt: new Date()
      }, { merge: true });

      // Update users collection
      const userRef = doc(db, 'users', user.uid);
      await setDoc(userRef, {
        firstName: editedFields.displayName.split(' ')[0],
        lastName: editedFields.displayName.split(' ').slice(1).join(' '),
        email: user.email,
        mailingAddress: {
          street: editedFields.address.split(',')[0],
          city: editedFields.address.split(',')[1]?.trim() || '',
          state: editedFields.address.split(',')[2]?.trim().split(' ')[0] || '',
          zipCode: editedFields.address.split(',')[2]?.trim().split(' ')[1] || '',
          country: 'USA'
        }
      }, { merge: true });

      // Update local profile state
      setProfile({
        ...profile!,
        displayName: editedFields.displayName,
        address: editedFields.address
      });
      
      setIsEditing(false);
      console.log('Save successful');
    } catch (err) {
      console.error('Error updating profile:', err);
      setError('Failed to save changes');
    } finally {
      setIsSaving(false);
      console.log('Save completed, isSaving set to false');
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedFields({
      displayName: profile?.displayName || user?.displayName || '',
      address: profile?.address || ''
    });
    setError(null);
  };

  if (!user) return null;

  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Account Details Section */}
        <div className="bg-white shadow rounded-lg p-6 mb-8">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">Account Details</h2>
            {!isEditing && (
              <button
                onClick={() => setIsEditing(true)}
                className="text-amber-600 hover:text-amber-700 flex items-center text-sm"
              >
                <Pencil className="h-4 w-4 mr-1" />
                Edit Profile
              </button>
            )}
          </div>
          
          <div className="space-y-4">
            {/* Name Field */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Name</label>
              {isEditing ? (
                <input
                  type="text"
                  value={editedFields.displayName}
                  onChange={(e) => setEditedFields(prev => ({
                    ...prev,
                    displayName: e.target.value
                  }))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                  placeholder="Enter your name"
                />
              ) : (
                <p className="mt-1 text-gray-900">
                  {profile?.displayName || user?.displayName || 'Not set'}
                </p>
              )}
            </div>
            
            {/* Email Field - Read Only */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <p className="mt-1 text-gray-900">{user?.email}</p>
            </div>

            {/* Address Field */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Address</label>
              {isEditing ? (
                <div className="relative mt-1">
                  <input
                    ref={autocompleteInput}
                    type="text"
                    value={editedFields.address}
                    onChange={(e) => setEditedFields(prev => ({
                      ...prev,
                      address: e.target.value
                    }))}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                    placeholder="Start typing your address..."
                  />
                  {/* Optional loading indicator while Google Maps is initializing */}
                  {!isGoogleLoaded && (
                    <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                      <Loader2 className="h-4 w-4 animate-spin text-gray-400" />
                    </div>
                  )}
                </div>
              ) : (
                <p className="mt-1 text-gray-900">
                  {profile?.address || 'No address set'}
                </p>
              )}
            </div>

            {/* Edit Mode Buttons */}
            {isEditing && (
              <div className="flex gap-2 mt-4">
                <button
                  onClick={handleSave}
                  disabled={isSaving}
                  className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white 
                    ${isSaving 
                      ? 'bg-amber-400 cursor-not-allowed' 
                      : 'bg-amber-600 hover:bg-amber-700'} 
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500`}
                >
                  {isSaving ? (
                    <>
                      <Loader2 className="h-4 w-4 animate-spin mr-2" />
                      Saving...
                    </>
                  ) : (
                    <>
                      <Check className="h-4 w-4 mr-2" />
                      Save Changes
                    </>
                  )}
                </button>
                <button
                  onClick={handleCancel}
                  disabled={isSaving}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <X className="h-4 w-4 mr-2" />
                  Cancel
                </button>
              </div>
            )}

            {error && (
              <p className="mt-2 text-sm text-red-600">{error}</p>
            )}

            {/* Sign Out Button */}
            <button
              onClick={() => signOut()}
              className="mt-6 bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 transition-colors"
            >
              Sign Out
            </button>
          </div>
        </div>

        {/* Order History Section */}
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-bold mb-6">Order History</h2>
          <OrderHistory orders={orders} />
        </div>
      </div>
    </div>
  );
}